import React, { Component } from 'react'

export class BlockSocial extends Component {

    constructor(props){
        super(props);

        this.state = { blockInfo: props.info};

    }


    render() {
        return (
            <div style={{ marginTop: '5px'}}>
                <div  style={{ marginLeft: '-15px',  height: '50px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                { (this.state.blockInfo.facebook !== '' && this.state.blockInfo.facebook_icon !== '') &&
                    <img style={{ marginLeft: '15px', cursor: 'pointer', width: '20px', height: '20px'}} 
                    src={this.state.blockInfo.facebook_icon}
                    alt="fb"
                    onClick={()=> window.open(this.state.blockInfo.facebook, "_blank")}
                    />
                }

                { (this.state.blockInfo.instagram !== '' && this.state.blockInfo.instagram_icon !== '') &&
                    <img style={{ marginLeft: '15px', cursor: 'pointer', width: '20px', height: '20px'}} 
                    src={this.state.blockInfo.instagram_icon}
                    alt="fb"
                    onClick={()=> window.open(this.state.blockInfo.instagram, "_blank")}
                    />
                }

                { (this.state.blockInfo.twitter !== '' && this.state.blockInfo.twitter_icon !== '') &&
                    <img style={{ marginLeft: '15px', cursor: 'pointer', width: '20px', height: '20px'}} 
                    src={this.state.blockInfo.twitter_icon}
                    alt="fb"
                    onClick={()=> window.open(this.state.blockInfo.twitter, "_blank")}
                    />
                }

                { (this.state.blockInfo.youtube !== '' && this.state.blockInfo.youtube_icon !== '') &&
                    <img style={{ marginLeft: '15px', cursor: 'pointer', width: '20px', height: '20px'}} 
                    src={this.state.blockInfo.youtube_icon}
                    alt="fb"
                    onClick={()=> window.open(this.state.blockInfo.youtube, "_blank")}
                    />
                }


                { (this.state.blockInfo.linkedin !== '' && this.state.blockInfo.linkedin_icon !== '') &&
                    <img style={{ marginLeft: '15px', cursor: 'pointer', width: '20px', height: '20px'}} 
                    src={this.state.blockInfo.linkedin_icon}
                    alt="fb"
                    onClick={()=> window.open(this.state.blockInfo.linkedin, "_blank")}
                    />
                }

                </div>
            </div>
        )
    }
}

export default BlockSocial
