import React, { Component } from 'react'

export class BlockImage extends Component {

    constructor(props){
        super(props);

        this.state = { blockInfo: props.info};
    }

    render() {
        return (
            <div>
                {this.state.blockInfo.mode === 'extend' &&
                <div>
                <img style={{ maxWidth: '100%'}} 
                src={this.state.blockInfo.content}
                alt="content"
                />
                </div>
                }

                {this.state.blockInfo.mode === 'adapt' &&
                <div>
                <img style={{ width: '100%', maxHeight: '250px', resizeMode: 'contain', objectFit: 'cover'}} 
                src={this.state.blockInfo.content}
                alt="content"
                />
                </div>
                }

                {this.state.blockInfo.mode === 'round' &&
                <div>
                <br />
                <img style={{ width: '100px', height: '100px', resizeMode: 'contain', objectFit: 'cover', borderRadius: '50px'}} 
                src={this.state.blockInfo.content}
                alt="content"
                />
                </div>
                }

            </div>
        )
    }
}

export default BlockImage
