import React, { Component } from 'react'

export class BlockButton extends Component {

    constructor(props){
        super(props);

        this.state = { blockInfo: props.info, buttonOpacity: '1.0'};
    }

    openContent = () => {
        window.open(this.state.blockInfo.content, "_blank");
        this.addClick();
    }


    addClick = () => {
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
                      button: this.state.blockInfo.id
                    })
            };
          
                const apiUrl = 'https://getfy-business.com/user/v5/multilink/click';
      
          fetch(apiUrl, requestOptions);
    }

    mouseOver = () => {
        this.setState({ buttonOpacity: '0.9' });
    }
    
    mouseLeave = () => {
        this.setState({ buttonOpacity: '1.0' });
    }

    render() {
        return (
            <div style={{ margin: '15px'}}>
                <div  style={{  height: '50px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{ cursor: 'pointer',  fontWeight: this.state.blockInfo.text_weight, 
                            height: '50px', width: '100%', borderRadius: this.state.blockInfo.border_radius+'px', 
                            backgroundColor: this.state.blockInfo.color, fontSize: '15px', 
                            textAlign: 'center', display: 'flex', justifyContent: 'center', 
                            alignItems: 'center', color: this.state.blockInfo.text_color, 
                            opacity: this.state.buttonOpacity}}
                    onClick={this.openContent}
                    onMouseEnter={this.mouseOver}
                    onMouseLeave={this.mouseLeave}
                >
                {this.state.blockInfo.text}
                </div>              
                </div>
            </div>
        )
    }
}

export default BlockButton
