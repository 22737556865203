import React, { Component } from 'react'
import BlockAlbum from './BlockAlbum';
import BlockButton from './BlockButton';
import BlockImage from './BlockImage';
import BlockSocial from './BlockSocial';
import BlockText from './BlockText';
import BlockVideo from './BlockVideo';

export class BlockManager extends Component {

    constructor(props){
        super(props);

        this.state = { blockInfo: props.info};
    }

    render() {
        return (
            <div>
                {this.state.blockInfo.type === 'image' &&
                <BlockImage info={this.state.blockInfo} />
                }

                {this.state.blockInfo.type === 'text' &&
                <BlockText info={this.state.blockInfo} />
                }

                {this.state.blockInfo.type === 'button' &&
                <BlockButton info={this.state.blockInfo} />
                }

                {this.state.blockInfo.type === 'space' &&
                <br />
                }

                {this.state.blockInfo.type === 'social' &&
                <BlockSocial info={this.state.blockInfo} />
                }
               
                {this.state.blockInfo.type === 'video' &&
                <BlockVideo info={this.state.blockInfo} />
                }

                {this.state.blockInfo.type === 'album' &&
                <BlockAlbum info={this.state.blockInfo} />
                }

            </div>
        )
    }
}

export default BlockManager
