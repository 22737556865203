import React, { Component } from 'react'

export class BlockText extends Component {

    constructor(props){
        super(props);

        this.state = { blockInfo: props.info};

    }

    render() {
        return (
            <div>
                <div style={{ margin: '15px', color: this.state.blockInfo.color, fontSize: this.state.blockInfo.size+'px', fontWeight: this.state.blockInfo.weight}}>
                {this.state.blockInfo.content}
                </div>
            </div>
        )
    }
}

export default BlockText
