import logo from './logo.svg';
import logoBlack from './logo-black.svg';
import './App.css';
import { Component } from 'react';
import BlockManager from './BlockManager';
import MetaTags from 'react-meta-tags';

class App extends Component {
  
  intervalID = 0;
  navigation_time = 0;

  constructor(props){
    super(props);
    
    var landingCode = window.location.href.replace(window.location.origin, "");
    landingCode = landingCode.replace("/", "");
   

    this.state = { landingCode: landingCode, generalInfo: null, landingBlocks: [], backgroundColor: '#FFFFFF', endBackgroundColor: '#FFFFFF', logo: 'dark', session_id: null };
    
  }


  componentDidMount () {
    this.getLandingCodeInfo(this.state.landingCode);
  }

  getLandingCodeInfo = (code) => {

    if(code === ''){
      window.location.href = 'https://www.getfy.it/getfy/';
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
                code: code
              })
      };
    
          const apiUrl = 'https://getfy-business.com/user/v5/multilink/';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
     
      var generalInfo = r.info.main;
      if(generalInfo.end_background_color == ''){
        generalInfo.end_background_color = generalInfo.background_color;
      }
      this.intervalID = setInterval(this.navigationTime, 1000);
      this.elaborateColorIntensity( r.info.main.background_color);
      this.setState({ generalInfo: generalInfo, landingBlocks: r.info.blocks, backgroundColor: generalInfo.background_color, endBackgroundColor: generalInfo.end_background_color, session_id: r.session_id });
      this.setPageTitle(r.info.main.page_title);
      
    }).catch(e => {
    window.location.href = 'https://www.getfy.it/getfy/';
    });

  }

  elaborateColorIntensity = (bgColor) => {
    return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2) ? this.setState({ logo: 'dark' }) : this.setState({ logo: 'light' });
  }

  setPageTitle = (title) => {

    document.title = title;

  }

  navigationTime = () => {
    
    var time = 0;
    if(this.navigation_time == 5){
      clearInterval(this.intervalID);
      this.intervalID = setInterval(this.navigationTime, 5000);
    }else{
      this.navigation_time++;
      time = 1;
    }
    if(this.state.session_id != null && document.hasFocus()){
      const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
                session_id: this.state.session_id,
                time: time
              })
      };
    
          const apiUrl = 'https://getfy-business.com/user/v5/multilink/timing';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
      
      
    }).catch(e => {
     
    });
  }

  }

  render(){
    

    return (
     
    <div className="App" style={{ backgroundImage: 'linear-gradient(to bottom, '+this.state.backgroundColor+', '+this.state.endBackgroundColor+')'}}> 
      <MetaTags>
      {this.state.generalInfo !== null &&  <meta name="theme-color" content={this.state.generalInfo.background_color} /> }
      {this.state.generalInfo !== null &&  <meta property="og:title" content={this.state.generalInfo.page_title} /> }
      {this.state.generalInfo !== null &&  <meta name="description" content={this.state.generalInfo.page_description} /> }
      </MetaTags>
    <div className="BlockContainer">
          {this.state.landingBlocks.map((block, i) =>
              <BlockManager key={i} info={block} />
            )
          }

  { this.state.logo === 'dark' && 
    <img alt="Getfy" style={{marginTop: '70px', color: 'black', height: '18px'}} src={logoBlack} />
    }

  { this.state.logo === 'light' && 
    <img alt="Getfy" style={{marginTop: '70px', color: 'black', height: '18px'}} src={logo} />
    }
    </div>
    </div>
  );
    }
}


export default App;
